import React from "react";

export function Logo(props: Readonly<React.ComponentPropsWithoutRef<'svg'>>) {
  return (
    <svg aria-hidden="true" viewBox="0 0 220 80" {...({fill: "#FFFFFF", ...props})} >
      <g id="23fc0398-252f-41b6-b4f3-e65470431412" transform="matrix(8.086254119873047,0,0,8.086254119873047,-2.2641496658325195,-13.34231948852539)">
        <path
          d="M1.12 1.86L1.12 3.47L4.48 3.47L0.28 8.86L7.42 8.86L7.42 7.18L3.84 7.18L7.98 1.86L1.12 1.86ZM8.75 1.86L8.75 8.86Q10.08 8.86 11.41 8.86Q12.74 8.86 14.07 8.86L14.07 8.86L14.07 7.18L10.71 7.18L10.71 6.13L13.65 6.13L13.65 4.52L10.71 4.52L10.71 3.47L14.07 3.47L14.07 1.86Q12.74 1.86 11.41 1.86Q10.08 1.86 8.75 1.86L8.75 1.86ZM18.40 6.76L18.40 6.76Q18.40 7.08 18.12 7.20Q17.85 7.32 17.46 7.32L17.46 7.32Q17.12 7.32 16.72 7.18Q16.31 7.04 15.93 6.81Q15.54 6.58 15.26 6.31L15.26 6.31L14.70 7.92Q15.12 8.41 15.85 8.74Q16.58 9.07 17.60 9.07L17.60 9.07Q18.35 9.07 19.00 8.86Q19.64 8.64 20.03 8.13Q20.43 7.62 20.43 6.73L20.43 6.73Q20.43 6.13 20.11 5.73Q19.80 5.33 19.33 5.07Q18.86 4.80 18.40 4.61Q17.93 4.41 17.62 4.22Q17.30 4.03 17.30 3.78L17.30 3.78Q17.30 3.58 17.42 3.46Q17.54 3.33 17.84 3.33L17.84 3.33Q18.34 3.33 18.83 3.67Q19.32 4.00 19.74 4.41L19.74 4.41L20.30 2.87Q19.24 1.65 17.82 1.65L17.82 1.65Q17.07 1.65 16.46 1.90Q15.85 2.16 15.49 2.63Q15.13 3.11 15.13 3.78L15.13 3.78Q15.13 4.31 15.37 4.68Q15.61 5.04 15.98 5.30Q16.35 5.56 16.77 5.74Q17.18 5.92 17.55 6.08Q17.92 6.23 18.16 6.39Q18.40 6.55 18.40 6.76ZM21.06 1.86L21.06 3.54L23.16 3.54L23.16 8.86L25.12 8.86L25.12 3.54L27.22 3.54L27.22 1.86L21.06 1.86Z"></path>
      </g>
    </svg>
  )
}
